import React from 'react';
import clock from '../../static/images/neon-clock.png';
import dot from '../../static/images/dot.svg';
import bg2 from '../../static/images/bg2.jpg';
import bg4 from '../../static/images/bg4.jpg';
import managerIcon from '../../static/images/manager.png'
import gwardIcon from '../../static/images/gward.png'
import designIcon from '../../static/images/design.png'
import moneyIcon from '../../static/images/money.png'
import loveIcon from '../../static/images/love.png'
import nowebIcon from '../../static/images/noweb.png'
import anomimIcon from '../../static/images/anonim.png'

interface MainProps { }

const Main: React.FC<MainProps> = () => {
    return (
        <div>
            <div className="intro">
                <div className="_container">
                    <div className="intro__inner">
                        <div className="intro__title">Работай по своим правилам:</div>
                        <div className="intro__subtitle">Зарабатывай от 500 000 руб. до 10 млн в месяц</div>
                        <div className="intro__text">
                            <ul>
                                <li>- Свободный график</li>
                                <li>- Круглосуточная охрана</li>
                                <li>- Путешествия по миру</li>
                                <li>- Анонимно и Безопасно</li>
                                <li>- Работа есть абсолютно для каждой</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <section className="works" id={"works"}>
                <div className="works__inner _container">
                    <div className="section__title">
                        Работа для девушек в самом лучшем и безопасном эскорт сообществе:
                    </div>
                    <div className="works__cards">
                        <div className="works__card">
                            <img src={clock} alt=""/>
                            <div className="works__card-title">
                                свободный график
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={managerIcon} alt=""/>
                            <div className="works__card-title">
                                свой менеджер
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={gwardIcon} alt=""/>
                            <div className="works__card-title">
                                охрана 24/7
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={designIcon} alt=""/>
                            <div className="works__card-title">
                                личный стилист
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={moneyIcon} alt=""/>
                            <div className="works__card-title">
                                помогаем с первоначальными затратами
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={loveIcon} alt=""/>
                            <div className="works__card-title">
                                абсолютная свобода
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={anomimIcon} alt=""/>
                            <div className="works__card-title">
                                полная анонимность и безопасность (изучите раздел безопасность)
                            </div>
                        </div>
                        <div className="works__card">
                            <img src={nowebIcon} alt=""/>
                            <div className="works__card-title">
                                НИКАКОГО ВЕБ КАМ И СЪЕМОК – только абсолютно анонимная работа
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what">
                <div className="what__inner _container">
                    <div className="what__texts">
                        <div className="section__title">Что нужно делать:</div>
                        <div className="what__text">
                            <ul>
                                <li>сопровождение на мероприятиях</li>
                                <li>встречи</li>
                                <li>путешествия за границу</li>
                            </ul>
                        </div>
                    </div>
                    <div className="what__image">
                        <img src={bg2} alt="" />
                    </div>
                </div>
            </section>

            <section className="how" id={'how'}>
                <div className="_container">
                    <div className="how__inner">
                        <div className="section__title">Как все устроено:</div>
                        <div className="how__cards">
                            <div className="how__card">
                                <div className="how__number">1</div>
                                <div className="how__text">
                                   Связываетесь с менеджером – рассказываем, как все устроено (анонимно через Telegram, можете не показывать лицо и скрыть свои контактные данные)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">2</div>
                                <div className="how__text">
                                    Менеджер сообщит как начать работать, работать можно в любом городе. (сначала это в любом случае РФ, позже с опытом - можно будем переехать за границу, при желании)
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">3</div>
                                <div className="how__text">
                                    Приветствуется если вы будете с подругой / подругами
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">4</div>
                                <div className="how__text">
                                    Помогаем с переездом, жильем и др. расходами – на первое время
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">5</div>
                                <div className="how__text">
                                    Если необходимо - приводите себя в форму / порядок: спорт, косметолог и тд – мы оплатим эти расходы
                                </div>
                            </div>
                            <div className="how__card">
                                <div className="how__number">6</div>
                                <div className="how__text">
                                   И уже после работаете в свободном графике, вас не будут заставлять и требовать работать больше и тд – <p className={"bold-underline"}>то есть никакого давления</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="security" id={"security"}>
                <div className="_container">
                    <div className="security__inner">
                        <div className="section__title">
                            Анонимность и Безопасность
                        </div>
                        <div className="security__cards">
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Только у Нас абсолютно Анонимно и Безопасно</li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Круглосуточная охрана исключает любые сложности, 24/7 в любом месте и любой ситуации </li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Личный менеджер на связи 24/7 для решения любых вопросов </li>
                            </div>
                            <div className="security__card">
                                <li><img src={dot} alt="" /> Мы против веб кама и сьемок, наши сотрудницы ни где светятся, прибегаем к всевозможным современным методам безопасности – тем самым исключая что где-нибудь всплывает информация о том, чем вы занимаетесь – 100% анонимно </li>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact" id={"contact"}>
                <div className="contact__inner _container">
                    <div className="contact__image">
                        <img src={bg4} alt="" />
                    </div>
                    <div className="contact__texts">
                        <div className="section__title">
                            Связаться с нами
                        </div>
                        <div className="contact__text">
                            <div>Безопасно и Анонимно напишите менеджеру в Telegram</div>
                            <div>Ответим на все интересующие вопросы </div>
                        </div>
                        <a className="btn btn-purple" href={"https://t.me/Prime_22_xbot"}>
                            свяжитесь с нами
                        </a>
                    </div>
                </div>
            </section>

            <section></section>
        </div>
    );
}

export default Main;
