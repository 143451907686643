import React from 'react';

const Footer = () => {
    return (
        <div>
            <header className="header">
                <div className="header__inner _container">
                    <div className="header__title">
                        PRIME 18+
                    </div>
                    <nav className="nav">
                        <a href="#works" className="nav__link">Работа</a>
                        <a href="#how" className="nav__link">Как все устроено</a>
                        <a href="#security" className="nav__link">Безопасность</a>
                        <a href="#contact" className="nav__link">Связаться с нами</a>
                    </nav>
                </div>
            </header>
        </div>
    );
}

export default Footer;
